import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "navbar-brand",
  href: "#"
}
const _hoisted_4 = ["src"]

import YOOVLogo from '@/assets/yoov-logo.png'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("img", {
            src: _unref(YOOVLogo),
            class: "d-inline-block align-text-top"
          }, null, 8, _hoisted_4)
        ])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})